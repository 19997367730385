<template>
  <div>
    <img
      src="@/assets/images/title-bar.webp"
      style="width: 100%; margin-top: 70px"
    />

    <div style="margin-top: 100px">
      <div style="margin: 0 auto; max-width: 1500px">
        <div class="pane">
          <img src="@/assets/images/os.randore.webp" />
          <p>朗道运营系统</p>
          <a :href="osSite">进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/os.randore.webp" />
          <p>朗道科技运营系统</p>
          <a :href="techSite">进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/os.randore.webp" />
          <p>固汤运营系统</p>
          <a :href="gutangSite">进入系统</a>
        </div>
        
        <div class="pane">
          <img src="@/assets/images/library.randore.webp" />
          <p>朗道产品数据库</p>
          <a :href="rpmsSite">进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/email.randore.webp" />
          <p>朗道邮件系统</p>
          <a :href="emailSite">进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/freight.randore.webp" />
          <p>朗道海运系统</p>
          <a>进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/cabinet.webp" />
          <p>朗道RFID柜系统</p>
          <a :href="rfidSite">进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/mall.webp" />
          <p>朗道电商管理后台</p>
          <a href="https://www.randore.com/hainacms.php">进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/weight.webp" />
          <p>朗道称重柜系统</p>
          <a href="https://www.randos.cn">进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/cabinet.webp" />
          <p>君百智能柜系统</p>
          <a href="http://47.103.45.33:9090/">进入系统</a>
        </div>
        <div class="pane">
          <img src="@/assets/images/cabinet.webp" />
          <p>艾丰智能柜系统</p>
          <a href="http://www.ourvend.com:83/account/login">进入系统</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      rfidSite: "",
      osSite: "",
      techSite: "",
      gutangSite: "",
      rpmsSite: "",
      emailSite: "",
    };
  },
  mounted() {},
  created() {
    this.rfidSite = process.env.VUE_APP_RFID_SITE;
    this.osSite = process.env.VUE_APP_OS_SITE;
    this.techSite = process.env.VUE_APP_TECH_SITE;
    this.gutangSite = process.env.VUE_APP_GUTANG_SITE;
    this.rpmsSite = process.env.VUE_APP_RPMS_SITE;
    this.emailSite = process.env.VUE_APP_EMAIL_SITE;
  },
  methods: {
  },
};
</script>

<style>
body {
  background-image: url(../assets/images/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: center;
  padding: 0;
  margin: 0;
}

.pane {
  width: 240px;
  text-align: center;
  background-image: url(../assets/images/in-background.webp);
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 71px;
  padding-bottom: 40px;
  display: inline-block;
  margin: 0 28px 35px 28px;
}

.pane > img {
  width: 72px;
  height: 72px;
}

.pane > p {
  margin: 20px 0 39px 0;
  font-size: 16px;
  color: #ffffff;
}

.pane > a {
  border-radius: 18px;
  background: #f3c157;
  font-size: 16px;
  color: #081a4f;
  font-weight: 400;
  cursor: pointer;
  padding: 8px 43px;
  text-decoration: none;
}
</style>